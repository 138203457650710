// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { allapiAction } from "../Redux/common/action";
import { URL } from "../Redux/common/url";
var _ = require("lodash");

function Home() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  useEffect(() => {
    // battel_List
    getlist();
    return () => {};
  }, []);

  const [first, setfirst] = useState([]);
  const getlist = async () => {
    const response = await dispatch(allapiAction.getgametypeListUser());

    console.log(response?.data);
    setfirst(response?.data);
  };
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="container-fluid">
              <h6 className="text-white">Account Details</h6>
              <div className="row justify-content-center">
                <div className="col-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  >
                    <i
                      className="fa fa-user-circle-o fa-3x"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "13px",
                      textAlign: "center",
                    }}
                  >
                    My Profile
                  </p>
                </div>
                <div className="col-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  >
                    <i
                      className="fa fa-wallet fa-3x"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "13px",
                      textAlign: "center",
                    }}
                  >
                    My Wallet
                  </p>
                </div>
                <div className="col-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  >
                    <i
                      className="fa fa-trophy fa-3x"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "13px",
                      textAlign: "center",
                    }}
                  >
                    Top Players
                  </p>
                </div>
                <div className="col-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  >
                    <i
                      className="fa fa-headphones fa-3x"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "13px",
                      textAlign: "center",
                    }}
                  >
                    Contact Us
                  </p>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="homeslider">
                <Slider {...settings}>
                  <div>
                    <img
                      src="/assest/image/slider1.jpeg"
                      alt=""
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                  </div>
                  <div>
                    <img
                      src="/assest/image/slider1.jpeg"
                      alt=""
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                  </div>
                </Slider>
              </div>
            </div>

            <div className="container-fluid mt-4">
              <h6 className="text-white">My Contest</h6>
              <div className="row justify-content-center">
                <div className="col-4">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      width: "90px",
                      height: "100px",
                    }}
                    onClick={() => {
                      navigate("/upcoming");
                    }}
                  >
                    <i
                      className="fa fa-trophy fa-3x"
                      aria-hidden="true"
                      style={{ color: "red" }}
                    ></i>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Upcoming
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      width: "90px",
                      height: "100px",
                    }}
                  >
                    <i
                      className="fa fa-trophy fa-3x"
                      aria-hidden="true"
                      style={{ color: "red" }}
                    ></i>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Ongoing
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      width: "90px",
                      height: "100px",
                    }}
                    onClick={() => {
                      navigate("/complete");
                    }}
                  >
                    <i
                      className="fa fa-trophy fa-3x"
                      aria-hidden="true"
                      style={{ color: "red" }}
                    ></i>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "0px",
                        marginTop: "10px",
                      }}
                    >
                      Completed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <h6 className="text-white">Esports Games</h6>
              <div className="row justify-content-center">
                {first &&
                  first?.map((data, i) => {
                    return (
                      <div
                        className="col-4 mt-3"
                        onClick={() => {
                          navigate("/upcoming/" + data?._id);
                        }}
                      >
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "red",
                            borderRadius: "5px",
                            width: "100%",
                            overflow: "hidden",
                            // height: "100px",
                          }}
                        >
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            style={{
                              width: "100%",
                              height: "100px",
                            }}
                          />

                          <p
                            style={{
                              color: "#fff",
                              fontSize: "13px",
                              textAlign: "center",
                              marginBottom: "5px",
                              marginTop: "5px",
                            }}
                          >
                            {data?.gameName}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/1.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF SURVIVL
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/2.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF FULL MAP
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/3.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF FULL MAP 2
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/1.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF SURVIVL
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/2.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF FULL MAP
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/3.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF FULL MAP 2
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/1.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF SURVIVL
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/2.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF FULL MAP
                    </p>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div
                    className="icons d-flex justify-content-center align-items-center flex-column"
                    style={{
                      backgroundColor: "red",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                      // height: "100px",
                    }}
                  >
                    <img
                      src="/assest/image/3.jpeg"
                      style={{
                        width: "100%",
                        height: "100px",
                      }}
                    />

                    <p
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      FF FULL MAP 2
                    </p>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <button class="learnMOre">Learn More</button>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Home;
