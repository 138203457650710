// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import socket from "./Socket";
import moment from "moment";
import { BATTEL_LIST } from "../Redux/common/constant";
import { allapiAction } from "../Redux/common/action";
var _ = require("lodash");

function Complete() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  const battel_List = useSelector((state) =>
    state?.allapi?.battel_List ? state?.allapi?.battel_List : {}
  );

  const battelListAction = (data) => {
    return { type: BATTEL_LIST, payload: data };
  };

  useEffect(() => {
    // Listen for incoming messages
    socket.on("message", (data) => {
      console.log(data);

      if (id) {
        const opne = data?.data?.opne?.filter((data1) => data1?.gameId == id);
        const running = data?.data?.running?.filter(
          (data1) => data1?.gameId == id
        );
        dispatch(battelListAction({ opne, running }));
      } else {
        dispatch(battelListAction(data?.data));
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off("message");
    };
  }, []);

  useEffect(() => {
    hendleToget();
    return () => {};
  }, []);

  const hendleToget = async () => {
    // id

    const obj = { type: "classicmanual" };
    if (id) {
      obj.gameId = id;
    }
    dispatch(allapiAction.getbattellist(obj));
  };
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />
          <div className="headerContainermsrrr">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-4">
                <p
                  style={{
                    color: "#fff",
                    marginBottom: "0px",
                    paddingBottom: "5px",
                  }}
                  onClick={() => {
                    id ? navigate("/upcoming/" + id) : navigate("/upcoming");
                  }}
                >
                  Upcoming
                </p>
              </div>
              <div className="col-4">
                {" "}
                <p
                  style={{
                    color: "#fff",
                    marginBottom: "0px",
                    paddingBottom: "5px",
                  }}
                >
                  Ongoing
                </p>
              </div>
              <div className="col-4">
                {" "}
                <p
                  style={{
                    color: "#fff",
                    marginBottom: "0px",
                    paddingBottom: "5px",
                    borderBottom: "2px solid white",
                  }}
                >
                  Complete
                </p>
              </div>
            </div>
          </div>
          <div className="main-area " style={{ paddingTop: "100px" }}>
            <div className="container-fluid lottery">
              {/* <h1 class="text-center mb-4">Completed</h1> */}

              {
                battel_List?.complete && battel_List?.complete?.map((data,i)=>{
                  return(
                    <div class="match-card">
                <div class="row">
                  <div class="col-12">
                    <div className="row">
                      <div className="col-4">
                        <img src="https://via.placeholder.com/80" alt="" />
                      </div>
                      <div className="col-8">
                        <div class="match-title"  onClick={() => {
                                  navigate("/battel-details/" + data?._id);
                                }}>
                        {data?.roomName}
                        </div>
                        <div class="match-details">
                          Time:  {moment(data?.startDateTime)?.format(
                                                            "DD/MM/YYYY hh:MM A"
                                                          )}
                        </div>
                      </div>
                    </div>

                    <div class="divider"></div>
                    <div class="row ">
                      <div class="col-4">
                        <div class="match-details">PRIZE POOL:</div>
                        <div class="coin">🪙 {data?.prize}  </div>
                        <div class="match-details">TYPE</div>
                        <div class="match-details">
                          <b>{data?.batteltype}</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">PER KILL:</div>
                        <div class="coin">🪙 {data?.parKill}</div>
                        <div class="match-details">VERSION</div>
                        <div class="match-details">
                          <b>{data?.version}</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">ENTRY FEE:</div>
                        <div class="coin"> 🪙 {data?.entryFee}</div>
                        <div class="match-details">MAP</div>
                        <div class="match-details">
                          <b>{data?.map}</b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button class="btn btn-watch">WATCH MATCH</button>
                      </div>
                      <div className="col-6">
                        <button class="btn btn-joined">JOINED</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
                  )
                })
              }
              {/* <div class="match-card">
                <div class="row">
                  <div class="col-12">
                    <div className="row">
                      <div className="col-4">
                        <img src="https://via.placeholder.com/80" alt="" />
                      </div>
                      <div className="col-8">
                        <div class="match-title">
                          FIRST COME FIRST SERVE #19998 | UNLIMITED AMMO
                        </div>
                        <div class="match-details">
                          Time: 09/12/2024 at 06:20 PM
                        </div>
                      </div>
                    </div>

                    <div class="divider"></div>
                    <div class="row ">
                      <div class="col-4">
                        <div class="match-details">PRIZE POOL:</div>
                        <div class="coin">🪙 154 </div>
                        <div class="match-details">TYPE</div>
                        <div class="match-details">
                          <b>SOLO</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">PER KILL:</div>
                        <div class="coin">🪙 3</div>
                        <div class="match-details">VERSION</div>
                        <div class="match-details">
                          <b>TPP</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">ENTRY FEE:</div>
                        <div class="coin"> 🪙 FREE</div>
                        <div class="match-details">MAP</div>
                        <div class="match-details">
                          <b>Bermuda</b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button class="btn btn-watch">WATCH MATCH</button>
                      </div>
                      <div className="col-6">
                        <button class="btn btn-joined">JOINED</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="match-card">
                <div class="row">
                  <div class="col-12">
                    <div className="row">
                      <div className="col-4">
                        <img src="https://via.placeholder.com/80" alt="" />
                      </div>
                      <div className="col-8">
                        <div class="match-title">
                          FIRST COME FIRST SERVE #19998 | UNLIMITED AMMO
                        </div>
                        <div class="match-details">
                          Time: 09/12/2024 at 06:20 PM
                        </div>
                      </div>
                    </div>

                    <div class="divider"></div>
                    <div class="row ">
                      <div class="col-4">
                        <div class="match-details">PRIZE POOL:</div>
                        <div class="coin">🪙 154 </div>
                        <div class="match-details">TYPE</div>
                        <div class="match-details">
                          <b>SOLO</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">PER KILL:</div>
                        <div class="coin">🪙 3</div>
                        <div class="match-details">VERSION</div>
                        <div class="match-details">
                          <b>TPP</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">ENTRY FEE:</div>
                        <div class="coin"> 🪙 FREE</div>
                        <div class="match-details">MAP</div>
                        <div class="match-details">
                          <b>Bermuda</b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button class="btn btn-watch">WATCH MATCH</button>
                      </div>
                      <div className="col-6">
                        <button class="btn btn-joined">JOINED</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Complete;
