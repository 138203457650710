export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:5600",
  API_BASE_URL: "https://backend.hypergames.in",
  // API_BASE_URL: "https://win27backend.a2itsolution.com",
  // API_BASE_URL: "http://34.229.29.185:5600",
  // API_BASE_URL: "http://gamesdeveloper.in:3005",
  // API_BASE_URL: "https://foxadda.in:3024",
  //
  // apipublic
  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  login: "/publicApi/login",
  otpVerify: "/publicApi/otpVerify",
  register: "/publicApi/register",
  signinUser: "/publicApi/signinUser",

  getprofile: "/api/getprofile",
  addFound: "/api/addFound",
  addFound2: "/api/addFound2",
  createbattel: "/api/createbattel",
  getbattellist: "/api/getbattellist",
  getbattellist2: "/api/getbattellist2",
  joinbettel: "/api/joinbettel",
  getbattelDetails: "/api/getbattelDetails",
  submitWinner: "/api/submitWinner",
  startbettel: "/api/startbettel",
  startbettel2: "/api/startbettel2",
  getgametypeListUser: "/api/getgametypeListUser",
  getbattellistUser: "/api/getbattellistUser",
  getuserTransation: "/api/getuserTransation",
  createaccount: "/api/createaccount",
  updateaccount: "/api/updateaccount",
  getaccuontdetails: "/api/getaccuontdetails",
  getsetting: "/api/getsetting",
  withdrawaluserAmount: "/api/withdrawaluserAmount",
  getwithdrawallist: "/api/getwithdrawallist",
  cencelBettel: "/api/cencelBettel",
  phonePayResponse: "/api/phonePayResponse",
  checkuserName: "/api/checkuserName",
  edituserId: "/api/edituserId",
  edituserDetails: "/api/edituserDetails",
  selfchangepassword: "/api/selfchangepassword",
  joinBattelnew: "/api/joinBattelnew",
  battelDetails: "/api/battelDetails",
  editprofileImage: "/api/editprofileImage",
  searchuserforfriend: "/api/searchuserforfriend",
  sendfrindreq: "/api/sendfrindreq",
  frindrequestList: "/api/frindrequestList",
  Acceptreq: "/api/Acceptreq",
  friendList: "/api/friendList",
  fundTransfer: "/api/fundTransfer",
  addfundreqest: "/api/addfundreqest",
  metricsUser: "/api/metricsUser",
  createorderforupi: "/api/createorderforupi",
  getusernotification: "/api/getusernotification",
  notificationseen: "/api/notificationseen",
  getaccountListdetails: "/api/getaccountListdetails",
  addfundreqestListuser: "/api/addfundreqestListuser",
  cencelBettelAfterJoin: "/api/cencelBettelAfterJoin",
  getmessageuser: "/publicApi/getmessageuser",
};
