// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import moment from "moment";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { allapiAction } from "../Redux/common/action";
import { toast } from "react-toastify";
import socket from "./Socket";
import { BATTEL_LIST } from "../Redux/common/constant";
var _ = require("lodash");

function UpComing() {
  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  console.log(messages);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  const battel_List = useSelector((state) =>
    state?.allapi?.battel_List ? state?.allapi?.battel_List : {}
  );

  console.log(battel_List);
  console.log(profile?.users);
  const battelListAction = (data) => {
    return { type: BATTEL_LIST, payload: data };
  };

  useEffect(() => {
    // Listen for incoming messages
    socket.on("message", (data) => {
      console.log(data);

      if (id) {
        const opne = data?.data?.opne?.filter((data1) => data1?.gameId == id);
        const running = data?.data?.running?.filter(
          (data1) => data1?.gameId == id
        );
        dispatch(battelListAction({ opne, running }));
      } else {
        dispatch(battelListAction(data?.data));
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off("message");
    };
  }, []);

  useEffect(() => {
    hendleToget();
    return () => {};
  }, []);

  const hendleToget = async () => {
    // id

    const obj = { type: "classicmanual" };
    if (id) {
      obj.gameId = id;
    }
    dispatch(allapiAction.getbattellist(obj));
  };

  // joinBattelnew

  const [first, setfirst] = useState("");

  const hendleTojoin = async (e) => {
    if (e?.joinMember == e?.member) {
      toast?.error("full");
      return;
    }
    setfirst(e?._id);
    await dispatch(allapiAction.joinBattelnew({ _id: e?._id }));
    dispatch(allapiAction.getbattellist("classicmanual"));
  };

  console.log(1 + 2);
  // const ab = [1, 2, 3, 4, 5, 6, 7];
  // const abc = [{ name: "" }];
  // const as = _.chunk(ab, 2);

  // console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />
          <div className="headerContainermsrrr">
            <div className="row justify-content-center align-items-center text-center">
              <div className="col-4">
                <p
                  style={{
                    color: "#fff",
                    marginBottom: "0px",
                    paddingBottom: "5px",
                    borderBottom: "2px solid white",
                  }}
                >
                  Upcoming
                </p>
              </div>
              <div className="col-4">
                {" "}
                <p
                  style={{
                    color: "#fff",
                    marginBottom: "0px",
                    paddingBottom: "5px",
                  }}
                >
                  Ongoing
                </p>
              </div>
              <div className="col-4">
                {" "}
                <p
                  style={{
                    color: "#fff",
                    marginBottom: "0px",
                    paddingBottom: "5px",
                  }}
                  onClick={() => {
                    id ? navigate("/complete/" + id) : navigate("/complete");
                  }}
                >
                  Complete
                </p>
              </div>
            </div>
          </div>
          <div className="main-area" style={{ paddingTop: "100px" }}>
            <div className="container-fluid lottery">
              {battel_List?.opne &&
                battel_List?.opne?.map((data, i) => {
                  console.log(data);
                  {
                    /* profile?.users */
                  }
                  const getdata = data?.players?.find(
                    (e) => e == profile?.users?._id
                  );

                  console.log(getdata);
                  return (
                    <div class="match-card">
                      <div class="row">
                        <div class="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div
                                style={{
                                  marginTop: "-15px",
                                  marginLeft: "-15px",
                                  marginRight: "-15px",
                                  marginBottom: "15px",
                                }}
                              >
                                <img
                                  src="/assest/image/slider1.jpeg"
                                  alt=""
                                  style={{ width: "100%", borderRadius: "5px" }}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <img
                                src="https://via.placeholder.com/80"
                                alt=""
                              />
                            </div>
                            <div className="col-8">
                              <div
                                class="match-title"
                                onClick={() => {
                                  navigate("/battel-details/" + data?._id);
                                }}
                              >
                                {data?.roomName}
                              </div>
                              <div class="match-details">
                                Time:
                                {moment(data?.startDateTime)?.format(
                                  "DD/MM/YYYY hh:MM A"
                                )}
                                {/* 09/12/2024 at 06:20 PM */}
                              </div>
                            </div>
                          </div>

                          <div class="divider"></div>
                          <div class="row ">
                            <div class="col-4">
                              <div class="match-details">PRIZE POOL:</div>
                              <div class="coin">🪙 {data?.prize} </div>
                              <div class="match-details">TYPE</div>
                              <div class="match-details">
                                <b>{data?.batteltype}</b>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="match-details">PER KILL:</div>
                              <div class="coin">🪙 {data?.parKill}</div>
                              <div class="match-details">VERSION</div>
                              <div class="match-details">
                                <b>{data?.version}</b>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="match-details">ENTRY FEE:</div>
                              <div class="coin"> 🪙 {data?.entryFee}</div>
                              <div class="match-details">MAP</div>
                              <div class="match-details">
                                <b>{data?.map}</b>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8">
                              <div className="mt-2">
                                <div
                                  className="progress-bar"
                                  style={{
                                    background: "#e0e0e0",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <div
                                    className="progress-bar-fill"
                                    style={{
                                      width:
                                        (data?.joinMember / data?.member) *
                                          100 +
                                        "%", // Adjust this percentage to set the progress
                                      background: "#4caf50",
                                      height: "10px",
                                    }}
                                  ></div>
                                </div>
                                <div className="row mt-1">
                                  <div className="col-7">
                                    <p style={{ fontSize: "12px" }}>
                                      {" "}
                                      Only {data?.member -
                                        data?.joinMember}{" "}
                                      spots left
                                    </p>
                                  </div>
                                  <div className="col-5 text-end">
                                    <p style={{ fontSize: "12px" }}>
                                      {" "}
                                      {data?.joinMember} / {data?.member}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <button className="btn btn-watch">WATCH MATCH</button> */}
                            </div>

                            <div className="col-4">
                              {getdata ? (
                                <button
                                  class="btn btn-joined"
                                  // onClick={() => {
                                  //   hendleTojoin(data);
                                  // }}
                                >
                                  {getdata ? "JOINED" : "JOIN"}
                                </button>
                              ) : data?.joinMember == data?.member ? (
                                <button
                                  class="btn btn-joined"
                                  // onClick={() => {
                                  //   hendleTojoin(data);
                                  // }}
                                >
                                  FULL
                                </button>
                              ) : (
                                <button
                                  class="btn btn-joined"
                                  onClick={() => {
                                    // hendleTojoin(data);
                                    navigate("/choose-slot/" + data?._id);
                                  }}
                                >
                                  {getdata ? "JOINED" : "JOIN"}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div class="match-card">
                <div class="row">
                  <div class="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div
                          style={{
                            marginTop: "-15px",
                            marginLeft: "-15px",
                            marginRight: "-15px",
                            marginBottom: "15px",
                          }}
                        >
                          <img
                            src="/assest/image/slider1.jpeg"
                            alt=""
                            style={{ width: "100%", borderRadius: "5px" }}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <img src="https://via.placeholder.com/80" alt="" />
                      </div>
                      <div className="col-8">
                        <div class="match-title">
                          FIRST COME FIRST SERVE #19998 | UNLIMITED AMMO
                        </div>
                        <div class="match-details">
                          Time: 09/12/2024 at 06:20 PM
                        </div>
                      </div>
                    </div>

                    <div class="divider"></div>
                    <div class="row ">
                      <div class="col-4">
                        <div class="match-details">PRIZE POOL:</div>
                        <div class="coin">🪙 154 </div>
                        <div class="match-details">TYPE</div>
                        <div class="match-details">
                          <b>SOLO</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">PER KILL:</div>
                        <div class="coin">🪙 3</div>
                        <div class="match-details">VERSION</div>
                        <div class="match-details">
                          <b>TPP</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">ENTRY FEE:</div>
                        <div class="coin"> 🪙 FREE</div>
                        <div class="match-details">MAP</div>
                        <div class="match-details">
                          <b>Bermuda</b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button class="btn btn-watch">WATCH MATCH</button>
                      </div>
                      <div className="col-6">
                        <button class="btn btn-joined">JOINED</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="match-card">
                <div class="row">
                  <div class="col-12">
                    <div className="row">
                      <div className="col-12">
                        <div
                          style={{
                            marginTop: "-15px",
                            marginLeft: "-15px",
                            marginRight: "-15px",
                            marginBottom: "15px",
                          }}
                        >
                          <img
                            src="/assest/image/slider1.jpeg"
                            alt=""
                            style={{ width: "100%", borderRadius: "5px" }}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <img src="https://via.placeholder.com/80" alt="" />
                      </div>
                      <div className="col-8">
                        <div class="match-title">
                          FIRST COME FIRST SERVE #19998 | UNLIMITED AMMO
                        </div>
                        <div class="match-details">
                          Time: 09/12/2024 at 06:20 PM
                        </div>
                      </div>
                    </div>

                    <div class="divider"></div>
                    <div class="row ">
                      <div class="col-4">
                        <div class="match-details">PRIZE POOL:</div>
                        <div class="coin">🪙 154 </div>
                        <div class="match-details">TYPE</div>
                        <div class="match-details">
                          <b>SOLO</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">PER KILL:</div>
                        <div class="coin">🪙 3</div>
                        <div class="match-details">VERSION</div>
                        <div class="match-details">
                          <b>TPP</b>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="match-details">ENTRY FEE:</div>
                        <div class="coin"> 🪙 FREE</div>
                        <div class="match-details">MAP</div>
                        <div class="match-details">
                          <b>Bermuda</b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button class="btn btn-watch">WATCH MATCH</button>
                      </div>
                      <div className="col-6">
                        <button class="btn btn-joined">JOINED</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default UpComing;
