// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import moment from "moment";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { allapiAction } from "../Redux/common/action";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import socket from "./Socket";
var _ = require("lodash");

function ChoseSlot() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  console.log(id);
  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  console.log(profile);

  const navigate = useNavigate();
  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedCheckboxes1, setSelectedCheckboxes1] = useState([]);

  useEffect(() => {
    // Listen for incoming messages
    socket.on("joinMessage", (data) => {
      console.log(data);
      if (data == id) {
        hendleTojoin();
        setSelectedCheckboxes({});
        setSelectedCheckboxes1([]);
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off("joinMessage");
    };
  }, []);

  useEffect(() => {
    hendleTojoin();

    return () => {};
  }, []);
  const dispatch = useDispatch();
  const [battelDetails, setBattelDetails] = useState({});

  console.log(battelDetails);

  const hendleTojoin = async (e) => {
    // setfirst(e?._id);
    const response = await dispatch(allapiAction.battelDetails({ _id: id }));
    // dispatch(allapiAction.getbattellist("classicmanual"));
    console.log(response);
    setBattelDetails(response?.data);
  };
  const [box, setbox] = useState("0");

  console.log(selectedCheckboxes);
  console.log(selectedCheckboxes1);

  const handleColorChange = (rowIndex, field, value) => {
    setSelectedCheckboxes1((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };

  const [startBtmodal, setstartBtmodal] = useState(false);
  const [startBtmodal1, setstartBtmodal1] = useState("0");

  const hendletochangemodel = (e) => {
    console.log("e", e);

    setstartBtmodal1(e);
    setstartBtmodal(true);
  };

  const handleClose = () => setstartBtmodal(false);
  const handleShow = () => setstartBtmodal(true);

  const [startbtnshow, setstartbtnshow] = useState(true);
  const hendleTojoinm = async (e) => {
    setstartbtnshow(true);
    if (battelDetails?.joinMember == battelDetails?.member) {
      toast?.error("full");
      return;
    }

    const namefind = selectedCheckboxes1?.filter((data) => !data?.userName);
    // setfirst(e?._id);
    if (namefind?.length > 0) {
      toast?.error("please enter name");
      return;
    }
    const response = await dispatch(
      allapiAction.joinBattelnew({
        _id: battelDetails?._id,
        joininList: selectedCheckboxes1,
      })
    );
    if (response?.success) {
      dispatch(allapiAction.getbattellist("classicmanual"));
      setstartBtmodal(false);
      navigate("/upcoming");
    }
  };
  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    if (
      battelDetails?.winType == "perKill" ||
      battelDetails?.winType == "teamwin"
    ) {
      if (battelDetails?.batteltype == "Solo") {
        const finddata = selectedCheckboxes1?.find((data) => data?._id == id);
        console.log(finddata);

        if (finddata) {
        } else {
          const finddata2 = battelDetails?.listSlot?.find(
            (data) => data?._id == id
          );
          console.log(finddata2);
          console.log(selectedCheckboxes1);

          const finddata1 = selectedCheckboxes1?.find(
            (data) => data?.team == finddata2?.team
          );
          console.log(selectedCheckboxes1?.length > 0);
          if (selectedCheckboxes1?.length > 0) {
            return;
          } else {
          }
        }
      } else if (battelDetails?.batteltype == "Deo") {
        const finddata = selectedCheckboxes1?.find((data) => data?._id == id);
        console.log(finddata);

        if (finddata) {
        } else if (selectedCheckboxes1?.length == 0) {
        } else {
          const finddata2 = battelDetails?.listSlot?.find(
            (data) => data?._id == id
          );
          console.log(finddata2);
          console.log(selectedCheckboxes1);

          const finddata1 = selectedCheckboxes1?.find(
            (data) => data?.team == finddata2?.team
          );
          console.log(selectedCheckboxes1?.length > 0);
          if (!finddata1) {
            return;
          } else if (selectedCheckboxes1?.length > 2) {
            return;
          } else {
          }
        }
      } else {
        const finddata = selectedCheckboxes1?.find((data) => data?._id == id);
        console.log(finddata);

        if (finddata) {
        } else if (selectedCheckboxes1?.length == 0) {
        } else {
          const finddata2 = battelDetails?.listSlot?.find(
            (data) => data?._id == id
          );
          console.log(finddata2);
          console.log(selectedCheckboxes1);

          const finddata1 = selectedCheckboxes1?.find(
            (data) => data?.team == finddata2?.team
          );
          console.log(selectedCheckboxes1?.length > 0);
          if (!finddata1) {
            return;
          } else if (selectedCheckboxes1?.length > 4) {
            return;
          } else {
          }
        }
      }
    } else {
      const finddata = selectedCheckboxes1?.find((data) => data?._id == id);
      console.log(finddata);

      if (finddata) {
      } else {
        const finddata2 = battelDetails?.listSlot?.find(
          (data) => data?._id == id
        );
        console.log(finddata2);
        console.log(selectedCheckboxes1);

        console.log(selectedCheckboxes1?.length > 0);
        if (selectedCheckboxes1?.length > 0) {
          return;
        } else {
        }
      }
    }

    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the checkbox state
    }));
    // conts battelDetails?.listSlot

    const finddata = selectedCheckboxes1?.find((data) => data?._id == id);
    if (finddata) {
      const fnd = selectedCheckboxes1?.filter((data) => id !== data?._id);
      setSelectedCheckboxes1(fnd);
    } else {
      const finddata2 = battelDetails?.listSlot?.find(
        (data) => data?._id == id
      );
      setSelectedCheckboxes1([...selectedCheckboxes1, finddata2]);
    }
  };

  // battelDetails?.listSlot
  const finaldata = battelDetails?.listSlot ? battelDetails?.listSlot : [];
  // const groupedData = finaldata.reduce((acc, item) => {
  //   // टीम के अनुसार ग्रुप बनाना
  //   if (!acc[item.team]) {
  //     acc[item.team] = [];
  //   }
  //   acc[item.team].push(item);
  //   return acc;
  // }, {});
  const groupedData = finaldata.reduce((acc, item) => {
    if (!acc[item.team]) {
      acc[item.team] = [];
    }
    acc[item.team].push(item);
    return acc;
  }, {});

  console.log(groupedData);
  console.log(battelDetails?.winType);
  console.log(battelDetails?.batteltype);
  return (
    <div>
      <div id="root">
        <Modal show={startBtmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Register Your Game</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <label>Room code</label> */}

            {selectedCheckboxes1 &&
              selectedCheckboxes1?.map((data, i) => {
                return (
                  i == startBtmodal1 && (
                    <div>
                      <label>Game user name</label>
                      <input
                        name="userName"
                        className="form-control mt-3"
                        placeholder="enter game user name"
                        type={"text"}
                        value={data?.userName}
                        onChange={(e) => {
                          handleColorChange(i, "userName", e?.target?.value);
                        }}
                      />
                    </div>
                  )
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {startbtnshow && (
              <Button variant="primary" onClick={handleClose}>
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area">
            {box == "0" ? (
              <div className="bg-white  mt-5" style={{ height: "100vh" }}>
                <div class="container-fluid mt-5">
                  <div class="card shadow">
                    <div class="card-header py-3">
                      {battelDetails?.winType == "perKill" ||
                      battelDetails?.winType == "teamwin" ? (
                        battelDetails?.batteltype == "Solo" ? (
                          <div className="row">
                            {battelDetails?.listSlot &&
                              battelDetails?.listSlot?.map((data, i) => {
                                return (
                                  <div
                                    className="col-3"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>{i + 1}</span>
                                    <input
                                      type="checkbox"
                                      disabled={
                                        data?.playJoin == "true" ? true : false
                                      }
                                      checked={
                                        data?.playJoin == "true"
                                          ? data?.playJoin
                                          : !!selectedCheckboxes[data._id]
                                      } // Bind checkbox to state
                                      onChange={() =>
                                        handleCheckboxChange(data._id)
                                      } // Handle state change
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div>
                            <div className="row">
                              <div
                                className="col-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span>{"Slot"}</span>
                              </div>
                              <div
                                className="col-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span>{"A"}</span>
                              </div>
                              <div
                                className="col-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span>{"B"}</span>
                              </div>
                              {battelDetails?.batteltype == "Squad" && (
                                <div
                                  className="col-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{"C"}</span>
                                </div>
                              )}
                              {battelDetails?.batteltype == "Squad" && (
                                <div
                                  className="col-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{"D"}</span>
                                </div>
                              )}
                            </div>

                            {Object.entries(groupedData) &&
                              Object.entries(groupedData)?.map(
                                ([team, members]) => {
                                  console.log(members);
                                  return (
                                    <div className="row">
                                      <div
                                        className="col-3"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>{team}</span>
                                      </div>
                                      {members?.map((data, i) => {
                                        return (
                                          <div
                                            className="col-2"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            {/* <span>{data?.position}</span> */}
                                            <input
                                              type="checkbox"
                                              disabled={
                                                data?.playJoin == "true"
                                                  ? true
                                                  : false
                                              }
                                              checked={
                                                data?.playJoin == "true"
                                                  ? data?.playJoin
                                                  : !!selectedCheckboxes[
                                                      data._id
                                                    ]
                                              } // Bind checkbox to state
                                              onChange={() =>
                                                handleCheckboxChange(data._id)
                                              } // Handle state change
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        )
                      ) : (
                        <div className="row">
                          {battelDetails?.listSlot &&
                            battelDetails?.listSlot?.map((data, i) => {
                              return (
                                <div
                                  className="col-3"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{i + 1}</span>
                                  <input
                                    type="checkbox"
                                    disabled={
                                      data?.playJoin == "true" ? true : false
                                    }
                                    checked={
                                      data?.playJoin == "true"
                                        ? data?.playJoin
                                        : !!selectedCheckboxes[data._id]
                                    } // Bind checkbox to state
                                    onChange={() =>
                                      handleCheckboxChange(data._id)
                                    } // Handle state change
                                  />
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      onClick={() => {
                        if (selectedCheckboxes1?.length == 0) {
                          toast?.error("please select position");
                        } else {
                          setbox("1");
                        }
                      }}
                      className="btn btn-primary w-100"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="  mt-5" style={{ height: "100vh" }}>
                <div className="container-fluid lottery">
                  <div>
                    <div className="row justify-content-center">
                      <div className="col-12 text-center  mt-4 mb-4">
                        <span class="coin1">
                          🪙
                          <span className="text-white">
                            {profile?.wallets?.closeBal}
                          </span>{" "}
                        </span>
                      </div>
                      <div className="col-4  text-center">
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100px",
                            height: "100px",
                          }}
                          //   onClick={() => {
                          //     navigate("/upcoming");
                          //   }}
                        >
                          <span class="coin">
                            🪙
                            <span className="text-black">
                              {profile?.wallets?.closeBal}
                            </span>{" "}
                          </span>
                          <p
                            style={{
                              color: "#000",
                              fontSize: "15px",
                              textAlign: "center",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            Deposited
                          </p>
                        </div>
                      </div>
                      <div className="col-4  text-center">
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100px",
                            height: "100px",
                          }}
                          //   onClick={() => {
                          //     navigate("/upcoming");
                          //   }}
                        >
                          <span class="coin">
                            🪙
                            <span className="text-black">
                              {profile?.wallets?.winningBal}
                            </span>{" "}
                          </span>
                          <p
                            style={{
                              color: "#000",
                              fontSize: "15px",
                              textAlign: "center",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            Winning
                          </p>
                        </div>
                      </div>
                      <div className="col-4  text-center">
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100px",
                            height: "100px",
                          }}
                          //   onClick={() => {
                          //     navigate("/upcoming");
                          //   }}
                        >
                          <span class="coin">
                            🪙
                            <span className="text-black">
                              {profile?.users?.totalBonus}
                            </span>{" "}
                          </span>
                          <p
                            style={{
                              color: "#000",
                              fontSize: "15px",
                              textAlign: "center",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            Bonus
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="myWalletC">
                      <div className="myWalletmain p-3">
                        {/* <h6>My Wallet</h6> */}

                        <div className="container">
                          <div
                            style={{
                              background: "red",
                              padding: "12px",
                              borderRadius: "5px",
                            }}
                          >
                            <div className="row ">
                              <div className="col-12">
                                <h6>Enter Player Detials</h6>
                              </div>
                              <div className="col-4">
                                <h6 style={{ fontSize: "13px" }}>Team</h6>
                                <p>A</p>
                              </div>
                              <div className="col-4">
                                <h6 style={{ fontSize: "13px" }}>Position</h6>
                                {selectedCheckboxes1 &&
                                  selectedCheckboxes1?.map((data, i) => {
                                    return (
                                      <p style={{ marginBottom: "0px" }}>
                                        {data?.position}
                                      </p>
                                    );
                                  })}
                              </div>
                              <div className="col-4">
                                <h6 style={{ fontSize: "13px" }}>
                                  Player Details
                                </h6>
                                {selectedCheckboxes1 &&
                                  selectedCheckboxes1?.map((data, i) => {
                                    return (
                                      <p style={{ marginBottom: "0px" }}>
                                        {data?.userName
                                          ? data?.userName
                                          : "Palery" + (i + 1)}{" "}
                                        <span
                                          onClick={() => hendletochangemodel(i)}
                                        >
                                          <i className="bi bi-pencil"></i>
                                        </span>
                                      </p>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>

                          <div className="mt-5">
                            <button
                              onClick={() => {
                                hendleTojoinm("1");
                              }}
                              className="btn btn-primary w-100"
                            >
                              JOIN NOW
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default ChoseSlot;
